import * as React from 'react';
import Layout from './../components/layout';
import SEO from './../components/seo';
import Comparison from './../components/Comparison';
import SignUp from './../components/SignUp';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="What makes it better" />
      <Comparison />
      <SignUp />
    </Layout>
  );
};

export default IndexPage;
