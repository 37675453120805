import React from 'react';
import styled from 'styled-components';

import { Text18, Title42, Title35 } from './Typography';
import Content from './Content';
import FloatingImages from './FloatingImages';
import BG from './../images/features-bg.png';
import * as StyleVars from './../cssVariables';

const ComparisonContainer = styled.section`
  background-color: #171a1d;
  padding-top: 40px;
  position: relative;
  padding-bottom: 150px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 60%;
    background: url(${BG});
    background-size: 100% 100%;
    top: -140px;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  color: white;
  flex: 0 1 40%;

  @media (${StyleVars.mediaMobile}) {
    flex: 0 1 100%;
  }
`;

const Result = styled.div`
  margin-top: 150px;
  margin-bottom: 70px;
`;

const ResultText = styled.div`
  text-align: left;
`;

const ResultTitle = styled(Title42)`
  margin-bottom: 30px;
`;

const ContentText = styled.div`
  width: 70%;
  margin: 0 auto;

  @media (${StyleVars.mediaTablet}) {
    width: 90%;
  }

  @media (${StyleVars.mediaMobile}) {
    width: 100%;
  }
`;

const ListItem = styled.li`
  font-size: 18px;
  line-height: 29px;
  letter-spacing: -0.019em;
  color: #ffffff;
  font-weight: 300;
`;

export default function Comparison(props) {
  return (
    <ComparisonContainer>
      <Content>
        <Title42>There are two main kinds of habit trackers:</Title42>
        <Flex>
          <Col>
            <Title42>Paper</Title42>
            <Text18>
              Usually a paper-based habit tracker is built as a table. Maybe you
              have a column for every day of the month, and then you have a row
              for every habit that you're trying to develop. Do the thing on a
              given day — say, meditate — and then check it off.
            </Text18>
          </Col>
          <Col>
            <Title42>Software</Title42>
            <Text18>
              Of course there's an app for that. You set yourself up with
              whatever activities you want to do, and tick them off. Maybe with
              a home screen widget, or an app on your phone, or even your watch.
            </Text18>
          </Col>
        </Flex>

        <FloatingImages />

        <ContentText>
          <Result>
            <ResultText>
              <ResultTitle>Not Good Enough</ResultTitle>
              <Text18>
                While the traditional paper and software habit trackers are
                well-intentioned, they're just not good enough:
              </Text18>
            </ResultText>
          </Result>

          <Title35>Issues with paper-based habit trackers</Title35>
          <ul>
            <ListItem>
              It's a piece of paper you have to carry around. It can get
              crumpled or wet.
            </ListItem>
            <ListItem>
              It's not very private. Habits are a personal thing, and it's easy
              to look over your shoulder and see what you've marked off.
            </ListItem>
            <ListItem>
              They assume a fixed timespan. Typically it's a month, and the idea
              is that you'll do each action once per day.
            </ListItem>
          </ul>

          <Title35>Issues with software-based habit trackers</Title35>
          <ul>
            <ListItem>These tend to get bloated and complicated.</ListItem>
            <ListItem>
              Every time you want to check off a habit, you have to use your
              device. There's a reason "spend more time on my phone" is not a
              popular New Year's resolution.
            </ListItem>
            <ListItem>
              Privacy is an issue, again. Not only is it easy to look over your
              shoulder, there's always analytics to consider. Does the developer
              know what habits you're tracking? Are you sure they don't?
            </ListItem>
            <ListItem>
              While some of these are a single purchase, other apps are
              subscription-based and get expensive over time.
            </ListItem>
          </ul>

          <Title35>Issues shared by both types</Title35>
          <Text18>
            Both types of trackers have privacy issues. They share some other
            flaws, too:
          </Text18>
          <ul>
            <ListItem>
              They're typically built for tracking multiple habits. But if
              you're trying to work on something, you need <em>focus</em>. You
              don't want to work on five or ten habits at once, but this is just
              what their design encourages.
            </ListItem>
            <ListItem>
              They emphasize failure. Seinfeld popularized a concept called
              "don't break the chain". Write something each and every day, mark
              it off — after a month, you "can't stop" because it'll "break the
              chain". But if you do happen to stop for a day, it's very visible,
              and it feels like failure. That is not the mindset to be building
              a new identity from.
            </ListItem>
          </ul>
        </ContentText>
      </Content>
    </ComparisonContainer>
  );
}
