import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby";

import LazyImage from './LazyImage'

const Container = styled.div`
  margin-top: 70px;
`

export default function FloatingImages(props) {
  const data = useStaticQuery(graphql`
    query {
      gallery: file(relativePath: { eq: "gallery.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [
              320
              500
              636
              751
              863
              957
              1164
            ]
            sizes: "(max-width: 1558px) 100vw, 1558px"
            maxWidth: 1174
            maxHeight: 604
            quality: 100
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return <Container>
    <LazyImage
      fluid={data.gallery.childImageSharp.fluid}
      loading="eager"
      alt="Gallery"
    />
  </Container>
}